import React from 'react'
import { graphql, Link } from 'gatsby'
import BackgroundImage from 'gatsby-background-image'
import { Breadcrumb } from 'gatsby-plugin-breadcrumb'

import '../styles/templates/ServicesPage.scss'

import { map as _map } from 'lodash'

// Components
import { TitleDefault, ContentDefault } from 'components/Elements'
import Layout from 'components/Layout'
import SEO from 'components/SEO'

import dateIcon from 'img/dateicon.svg'
import dateTimeIcon from 'img/datetimeicon.svg'
import standNumberIcon from 'img/standnumbericon.svg'

import '../styles/templates/EventsPage.scss'

export const pageQuery = graphql`
  query($wordpress_id: Int) {
    page: wordpressPage(wordpress_id: { eq: $wordpress_id }) {
      title
      path
      acf {
        headerImage: image_events_header {
          localFile {
            childImageSharp {
              fluid(quality: 100, maxWidth: 2000) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
              fixed(width: 800, toFormat: JPG) {
                src
              }
            }
          }
        }
        events {
          date_event_start
          date_event_end
          datetime_event
          info_event
          location_event
          standnumber_event
          title_event
        }
      }
      yoast_meta {
        name
        content
        property
      }
    }
  }
`

function EventsPage({
  data: { page },
  pageContext: {
    breadcrumb: { crumbs },
  },
}) {
  return (
    <Layout>
      {page.acf.headerImage && page.acf.headerImage.localFile ? (
        <SEO
          yoast={{ meta: page.yoast_meta }}
          pathname={page.path}
          image={page.acf.headerImage.localFile.childImageSharp.fixed.src}
          article
        />
      ) : (
        <SEO yoast={{ meta: page.yoast_meta }} pathname={page.path} />
      )}
      <div className="events-template">
        <section className="heroimage position-relative d-flex">
          <div className="container context">
            <div className="row py-4">
              <div className="col-lg-6">
                <TitleDefault className="mb-3 color-text-light">
                  <span dangerouslySetInnerHTML={{ __html: page.title }} />
                </TitleDefault>
                <div className="line mb-4" />
                <Breadcrumb
                  crumbs={crumbs}
                  crumbSeparator=">"
                  crumbLabel={page.title}
                />
              </div>
              <div className="col-lg-6" />
            </div>
          </div>
          <div className="heroimage-image row position-absolute">
            <div className="col-12 col-lg-5 fill" />
            <div className="heroimage-image-container col-lg-7 p-0">
              <BackgroundImage
                fluid={page.acf.headerImage.localFile.childImageSharp.fluid}
              />
            </div>
          </div>
        </section>
        <section className="events-section py-5">
          <div className="container">
            {_map(page.acf.events, event => (
              <div className="row events px-2 px-lg-0" key={event.title_event}>
                <div className="col-lg-3 col-md-4 p-4 mb-4 events-date">
                  <p>
                    {event.date_event_start}
                    <br />
                    <span>until</span>
                    <br />
                    {event.date_event_end}
                  </p>
                  <small>{event.location_event}</small>
                </div>
                <div className="col-lg-6 col-md-8 pl-lg-5 pt-sm-3 events-info">
                  <TitleDefault>{event.title_event}</TitleDefault>
                  <ContentDefault>
                    <p>
                      <img src={dateIcon} width="26" alt="" />
                      {event.date_event_start} 
                      {' '}
                      <span>until</span>
                      {' '}
                      {event.date_event_end}
                    </p>
                    <p>
                      <img src={dateTimeIcon} width="26" alt="" />
                      {event.datetime_event}
                    </p>
                    <p className="content-standnumber">
                      <img src={standNumberIcon} width="26" alt="" />
                      {event.standnumber_event}
                    </p>
                  </ContentDefault>
                </div>
                <div className="col-lg-12 pt-4 px-0 events-text">
                  <div dangerouslySetInnerHTML={{ __html: event.info_event }} />
                </div>
              </div>
            ))}
          </div>
        </section>
      </div>
    </Layout>
  )
}

export default EventsPage
